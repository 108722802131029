<template>
  <b-modal v-model="isModalOpen" :can-cancel="false" :width="700">
    <div class="af-modal-card">
      <template v-if="!submitted">
        <form @submit.prevent="submit">
          <validation-observer ref="observer">
            <p class="has-text-weight-bold is-size-4">
              {{ $t("af:application.contract_check.modal.mistake.title") }}
            </p>

            <p class="mb-5">
              {{ $t("af:application.contract_check.modal.mistake.message") }}
            </p>

            <BInputWithValidation
              v-model="comment"
              rules="required"
              :label="
                $t('af:application.contract_check.modal.mistake.label.comment')
              "
              label-position="on-border"
            />

            <b-field grouped class="mt-5">
              <b-button
                id="documentMistakeModalButtonSend"
                @click="submit"
                class="mr-2 is-blue"
                >{{
                  $t(
                    "af:application.contract_check.modal.mistake.button.submit"
                  )
                }}
              </b-button>

              <!--              <b-button type="is-text" @click="cancelModal"-->
              <!--                >{{-->
              <!--                  $t("af:application.contract_check.modal.mistake.button.close")-->
              <!--                }}-->
              <!--              </b-button>-->
            </b-field>

            <div class="close-btn cursor-pointer" @click="cancelModal">
              <b-icon icon-pack="fas" icon="times" type="is-grey-icon" />
            </div>
          </validation-observer>
        </form>
      </template>

      <template v-else>
        <p class="has-text-weight-bold is-size-4">
          {{
            $t("af:application.contract_check.modal.mistake.submitted.title")
          }}
        </p>

        <p class="mb-5">
          {{
            $t("af:application.contract_check.modal.mistake.submitted.message")
          }}
        </p>

        <b-field grouped class="mt-5">
          <b-button class="is-blue" @click="cancelModal"
            >{{
              $t(
                "af:application.contract_check.modal.mistake.submitted.button.close"
              )
            }}
          </b-button>
        </b-field>
      </template>
    </div>
  </b-modal>
</template>

<script>
import i18n from "../../plugins/i18n";

export default {
  name: "GeneratedDocumentsMistakeModal",
  components: {
    BInputWithValidation: () => import("@/components/BInputWithValidation.vue"),
  },
  data() {
    return {
      isModalOpen: false,
      comment: null,
      submitted: false,
    };
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
      this.submitted = false;
      this.comment = null;
    },
    cancelModal() {
      this.isModalOpen = false;
    },
    async submit() {
      const valid = await this.$refs.observer.validate();

      if (!valid) return;

      try {
        await this.$store.dispatch("loanApplication/signatureErrorFound", {
          comment: this.comment,
        });
        await this.$store.dispatch("loanApplication/pollStatus");
        await this.$store.dispatch("loanApplication/fetchSignatureProcess");
        this.submitted = true;
        this.$buefy.toast.open({
          message: i18n.t(
            "af:application.contract_check.modal.mistake.message.save_successful"
          ),
          type: "is-success",
        });
      } catch (ex) {
        console.error(ex);
        this.$buefy.toast.open({
          message: i18n.t(
            "af:application.contract_check.modal.mistake.message.save_failed"
          ),
          type: "is-danger",
        });
      }
    },
  },
};
</script>

<style scoped>
.button.is-text {
  text-decoration: none;
}
</style>
